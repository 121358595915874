body {

  background-color: #E85F5C;
  color:white;
}
nav{
  background-color:#773344 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card{
  margin-top:10px;
  background-color:#E85F5C;
}

h2{
  margin-top:10px;
}

.result{
  font-size:20pt;
  text-shadow: 1px 1px #CACACA;
  display:block;
}

.form-control{
  font-size:1.4rem;
}

.paceForm{
  font-size:1.4rem;
}